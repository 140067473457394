import { useAtom } from 'jotai'
import { Schemas } from '~/apis/types'
import { atomWithLocalStorage } from '~/util/hooks/atom-with-local-storage'

export type AudioRateStateStorageType = 0.7 | 1.0 | 1.2 | 1.5 | 2.0

const settingAtom = atomWithLocalStorage<Schemas.ClientAccountSettingResponseDto | null>('setting', null)
const audioRateAtom = atomWithLocalStorage<number>('audioRate', 1.0)

export const useAccountSettingState = () => {
    const [accountSetting, setAccountSetting] = useAtom(settingAtom)
    const [audioRate, setAudioRate] = useAtom(audioRateAtom)

    const clearAccountSetting = () => {
        setAccountSetting(null)
        setAudioRate(1.0)
    }

    return {
        accountSetting,
        setAccountSetting,
        audioRate,
        setAudioRate,
        clearAccountSetting,
    }
}
