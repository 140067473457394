import { BrowserTracing, init, setTags } from '@sentry/react'
import { FC } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { PageShell } from '~/renderer/PageShell'

export const Root: FC = () => {
    if (process.env.NODE_ENV === 'production') {
        init({
            dsn: process.env.SENTRY_DSN,
            environment: process.env.NODE_ENV,
            integrations: [new BrowserTracing()],
            tracesSampleRate: 1.0,
            sampleRate: 1.0,
            autoSessionTracking: true,
        })
        setTags({
            stage: process.env.STAGE,
        })
    }

    return (
        <BrowserRouter>
            <PageShell />
        </BrowserRouter>
    )
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<Root />)
