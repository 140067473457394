import { useAtom } from 'jotai'
import { Schemas } from '~/apis/types'
import { atomWithLocalStorage } from '~/util/hooks/atom-with-local-storage'

type AuthTokenType = Schemas.ClientAuthTokenResponseDto & { token_created_at: Date }

const accessTokenAtom = atomWithLocalStorage<AuthTokenType | null>('access-token', null)
const meAtom = atomWithLocalStorage<Schemas.CorporationUserEntities | null>('me', null)

export const useAuthState = () => {
    const [accessToken, setAccessToken] = useAtom(accessTokenAtom)
    const [me, setMe] = useAtom(meAtom)

    const clearAll = () => {
        setAccessToken(null)
        setMe(null)
    }

    return {
        accessToken,
        setAccessToken,
        me,
        setMe,
        clearAll,
    }
}
