import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, useDisclosure } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { CButton } from '~/components/common/cButton/CButton'

import { DialogActionType, useDialog } from '~/hooks/use-dialog/use-dialog'

const GDialogActionButton: FC<DialogActionType> = (props) => {
    const { label, type, onClick } = props

    return (
        <CButton onClick={onClick} buttonType={type} _style={{ width: 'full' }}>
            {label}
        </CButton>
    )
}

// logic
const useGDialog = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    // locationが変わっても開き続けてしまうので強制的に閉じる
    const location = useLocation()
    useEffect(() => {
        onClose()
    }, [location, onClose])

    const { dialogContent: _dialogContent } = useDialog()
    const dialogContent = useMemo<typeof _dialogContent>(() => {
        if (!_dialogContent) return null
        return {
            ..._dialogContent,
            actions: _dialogContent.actions.map((it) => {
                return {
                    ...it,
                    onClick: () => {
                        if (it.onClick) it.onClick()
                        onClose()
                    },
                }
            }),
        }
    }, [_dialogContent, onClose])

    // ダイアログの内容がセットされたらダイアログを開く
    useEffect(() => {
        if (!dialogContent) onClose()
        else onOpen()
    }, [dialogContent, onClose, onOpen])

    return { isOpen, onOpen, onClose, dialogContent }
}

// view
const GDialogView: FC<ReturnType<typeof useGDialog>> = (props) => {
    const { isOpen, onClose, dialogContent } = props

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'xs', lg: 'sm' }} isCentered>
                <ModalOverlay bg={'rgba(0,0,0,0.5)'} />
                <ModalContent rounded={'lg'}>
                    <ModalHeader textAlign={'center'}>{dialogContent?.title}</ModalHeader>
                    <ModalBody textAlign={'center'} fontSize={'14px'}>
                        {dialogContent?.body}
                    </ModalBody>
                    <Stack overflow={'hidden'} m={4} flexDirection={'row'} justifyContent={'space-between'} spacing={4}>
                        {dialogContent?.actions.map((it, idx) => (
                            <GDialogActionButton {...it} key={idx} />
                        ))}
                    </Stack>
                </ModalContent>
            </Modal>
        </>
    )
}

// component
export const GDialog: FC = () => {
    const hookItems = useGDialog()
    return <GDialogView {...hookItems} />
}
