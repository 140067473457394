import { Box, ChakraProvider, Container, Flex, Stack, Text } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import { ErrorBoundary } from '@sentry/react'
import { QueryClient, QueryClientProvider, useQueryErrorResetBoundary } from '@tanstack/react-query'
import axios from 'axios'
import { Provider as JotaiProvider } from 'jotai'
import { StrictMode, Suspense, useMemo } from 'react'
import { FilledContext, HelmetProvider } from 'react-helmet-async'

import { useLocation } from 'react-router'
import { Navigate, useRoutes } from 'react-router-dom'
import { LoginShell } from './LoginShell'
import ogpIcon from '~/assets/images/icon.png'
import { CButton } from '~/components/common/cButton/CButton'
import { CLpHeader } from '~/components/functional/lp/cLpHeader/CLpHeader'
import { GDialog } from '~/components/global/gDialog/GDialog'
import { GHeader } from '~/components/global/gHeader/GHeader'
import { useAuthState } from '~/hooks/store/use-auth-state'
import { GA4 } from '~/renderer/GA4'
import { Meta } from '~/renderer/Meta'
import { theme } from '~/util/chakra-theme'
import routes from '~react-pages'

const helmetContext = {} as FilledContext

export const PageShell = () => {
    const { clearAll } = useAuthState()
    const queryClient = useMemo(() => new QueryClient(), [])
    const { reset } = useQueryErrorResetBoundary()
    const location = useLocation()

    window.scrollTo({ top: 0 })

    const isLpPage = useMemo(() => {
        const currentPath = location.pathname
        return currentPath === '/'
    }, [location.pathname])

    return (
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <JotaiProvider>
                    <ChakraProvider theme={theme} resetCSS={true}>
                        <HelmetProvider context={helmetContext}>
                            <GA4 trackingCode={process.env.GA4_ID!} isEnable={process.env.STAGE !== 'local'} />
                            <Meta ogpIcon={ogpIcon} />
                            <Global
                                styles={css`
                                    body,
                                    html,
                                    #page-view {
                                        height: 100%;
                                        color: #333333;
                                        background: ${isLpPage ? '#ffffff' : '#28529b'};
                                        font-family: Lato, Noto Sans JP, %E6%B8%B8%E3%82%B4%E3%82%B7%E3%83%83%E3%82%AF Medium,
                                            %E6%B8%B8%E3%82%B4%E3%82%B7%E3%83%83%E3%82%AF%E4%BD%93, Yu Gothic Medium, YuGothic,
                                            %E3%83%92%E3%83%A9%E3%82%AE%E3%83%8E%E8%A7%92%E3%82%B4 ProN, Hiragino Kaku Gothic ProN,
                                            %E3%83%A1%E3%82%A4%E3%83%AA%E3%82%AA, Meiryo,
                                            %EF%BC%AD%EF%BC%B3 %EF%BC%B0%E3%82%B4%E3%82%B7%E3%83%83%E3%82%AF, MS PGothic;
                                    }
                                `}
                            />
                            <LoginShell>
                                {isLpPage && <CLpHeader />}
                                <Container
                                    maxWidth={isLpPage ? 'full' : 1280}
                                    minHeight={'100vh'}
                                    centerContent
                                    bg={'#ffffff'}
                                    p={0}>
                                    <ErrorBoundary
                                        onReset={reset}
                                        fallback={({ resetError, error }) => {
                                            const isReload = axios.isAxiosError(error)
                                                ? error.response?.data?.statusCode !== 401
                                                : true
                                            return (
                                                <>
                                                    <GHeader />
                                                    <Box
                                                        width={'full'}
                                                        fontSize={'14px'}
                                                        height={'calc(100vh - 50px)'}
                                                        pt={'68px'}
                                                        overflow={'auto'}>
                                                        <Stack padding={'20px'} spacing={4}>
                                                            <Text fontSize={'16px'}>{error.message}</Text>
                                                            <Flex
                                                                display="flex"
                                                                flexDirection={'row'}
                                                                justifyContent={'start'}
                                                                gap={4}>
                                                                {isReload && (
                                                                    <CButton
                                                                        onClick={() => {
                                                                            resetError()
                                                                            window.location.reload()
                                                                        }}>
                                                                        再読み込み
                                                                    </CButton>
                                                                )}
                                                                <CButton
                                                                    buttonType={'primary'}
                                                                    onClick={async () => {
                                                                        resetError()
                                                                        clearAll()
                                                                        window.location.href = '/user/signIn'
                                                                    }}>
                                                                    サインインへ
                                                                </CButton>
                                                            </Flex>
                                                        </Stack>
                                                    </Box>
                                                </>
                                            )
                                        }}>
                                        {!isLpPage && <GHeader />}
                                        <Suspense
                                            fallback={
                                                <Box mt={'20%'} fontSize={'36px'}>
                                                    Loading...
                                                </Box>
                                            }>
                                            {useRoutes([
                                                ...routes,
                                                {
                                                    path: '*',
                                                    element: <Navigate to={'/user/signIn'} replace />,
                                                },
                                            ])}
                                        </Suspense>
                                    </ErrorBoundary>
                                </Container>
                            </LoginShell>
                            <GDialog />
                        </HelmetProvider>
                    </ChakraProvider>
                </JotaiProvider>
            </QueryClientProvider>
        </StrictMode>
    )
}
