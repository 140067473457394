import { useAtom } from 'jotai'
import { Schemas } from '~/apis/types'

import { atomWithLocalStorage } from '~/util/hooks/atom-with-local-storage'

const selectDateAtom = atomWithLocalStorage<string | null>('select-date', null)
const maxDateAtom = atomWithLocalStorage<string | null>('max-date', null)
const minDateAtom = atomWithLocalStorage<string | null>('min-date', null)
const selectIssueAtom = atomWithLocalStorage<Schemas.ClientIssueDetailResponseDto | null>('select-issue', null)
const refetchScoreAtom = atomWithLocalStorage<boolean>('refetch-score', false)
const uuidListAtom = atomWithLocalStorage<string[]>('uuid-list', [])

export const useUserParamState = () => {
    const [userSelectDate, setUserSelectDate] = useAtom(selectDateAtom)
    const [userMaxDate, setUserMaxDate] = useAtom(maxDateAtom)
    const [userMinDate, setUserMinDate] = useAtom(minDateAtom)
    const [userSelectIssue, setUserSelectIssue] = useAtom(selectIssueAtom)
    const [userRefetchScore, setUserRefetchScore] = useAtom(refetchScoreAtom)
    const [userUuidList, setUserUuidList] = useAtom(uuidListAtom)

    const resetUserParamState = () => {
        setUserSelectDate(null)
        setUserMaxDate(null)
        setUserMinDate(null)
        setUserSelectIssue(null)
        setUserRefetchScore(false)
        setUserUuidList([])
    }

    return {
        userSelectDate,
        setUserSelectDate,
        userMaxDate,
        setUserMaxDate,
        userMinDate,
        setUserMinDate,

        userSelectIssue,
        setUserSelectIssue,
        userRefetchScore,
        setUserRefetchScore,
        userUuidList,
        setUserUuidList,

        resetUserParamState,
    }
}
