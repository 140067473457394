import { Icon } from '@chakra-ui/icon'
import { Image } from '@chakra-ui/image'
import { Box, Divider, Stack, Text } from '@chakra-ui/layout'
import { useMemo, type FC } from 'react'
import { MdCalendarToday, MdLogout } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'

import imageLissnLogo from '~/assets/images/lissn_logo.png'
import imageTabBookmarkOff from '~/assets/images/tab/tab_bookmark_off.png'
import imageTabBookmarkOn from '~/assets/images/tab/tab_bookmark_on.png'
import imageTabHomeOff from '~/assets/images/tab/tab_home_off.png'
import imageTabHomeOn from '~/assets/images/tab/tab_home_on.png'
import imageTabMemoOff from '~/assets/images/tab/tab_memo_off.png'
import imageTabMemoOn from '~/assets/images/tab/tab_memo_on.png'
import imageTabSettingOff from '~/assets/images/tab/tab_setting_off.png'
import imageTabSettingOn from '~/assets/images/tab/tab_setting_on.png'
import { useAuthState } from '~/hooks/store/use-auth-state'
import { useUserParamState } from '~/hooks/store/use-user-param-state'
import { useDialog } from '~/hooks/use-dialog/use-dialog'
import { NsPropTypeBase } from '~/types/ns-prop-type-base'

export type GHeaderProps = {} & NsPropTypeBase

// logic
export const useGHeader = () => {
    const { me, clearAll } = useAuthState()
    const navigate = useNavigate()
    const { showDialog } = useDialog()
    const { resetUserParamState } = useUserParamState()

    const location = useLocation()
    const current = useMemo(() => {
        if (location.pathname.startsWith('/bookmark')) return 'bookmark'
        if (location.pathname.startsWith('/memo')) return 'memo'
        if (location.pathname.startsWith('/detail')) return 'home'
        if (location.pathname.startsWith('/setting')) return 'setting'
        if (location.pathname.startsWith('/info')) return 'setting'
        if (location.pathname.startsWith('/variousSettings')) return 'setting'
        if (location.pathname.startsWith('/resetPass')) return 'setting'

        return 'home'
    }, [location.pathname])

    const handlePageTransition = (page: string) => {
        navigate(page)
    }

    const logout = () => {
        showDialog({
            body: 'サインアウトしてよろしいですか？',
            actions: [
                {
                    label: '実行',
                    type: 'primary',
                    onClick: () => {
                        clearAll()
                        resetUserParamState()
                        navigate('/user/signIn')
                    },
                },
                {
                    label: 'キャンセル',
                },
            ],
        })
    }

    return {
        me,
        pathname: location.pathname,
        current,
        handlePageTransition,
        logout,
    }
}

// view
export const GHeaderView: FC<GHeaderProps & ReturnType<typeof useGHeader>> = (props) => {
    const { me, pathname, current, handlePageTransition, logout } = props

    return (
        <>
            {me && (
                <>
                    <Box
                        data-testid="g-header"
                        width={'full'}
                        maxWidth={1280}
                        height={'68px'}
                        border={'1px solid #4a90e2'}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        px={{ base: '0.5rem', lg: '0.8rem' }}
                        pos={'fixed'}
                        zIndex={999}
                        backgroundColor={'#fff'}>
                        <Image
                            width={{ base: '100px', lg: '130px' }}
                            src={imageLissnLogo}
                            alt="Lissn"
                            cursor={'pointer'}
                            onClick={() => handlePageTransition('/home')}
                        />
                        <Box
                            display={{ base: 'none', lg: 'block' }}
                            flex={'1 1 auto'}
                            maxWidth={'400px'}
                            paddingLeft={'1vw'}
                            marginRight={'auto'}>
                            <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'start'}
                                height={'68px'}
                                py={'1.25rem'}
                                pl={'3rem'}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    width={'full'}
                                    cursor={'pointer'}
                                    onClick={() => handlePageTransition('/home')}>
                                    <Image src={current === 'home' ? imageTabHomeOn : imageTabHomeOff} width={'32px'} />
                                    <Text fontSize={'10px'} color={current === 'home' ? 'primary' : undefined}>
                                        ホーム
                                    </Text>
                                </Box>

                                <Divider borderColor={'primary'} borderWidth={'1px'} orientation="vertical" />

                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    width={'full'}
                                    cursor={'pointer'}
                                    onClick={() => handlePageTransition('/memo')}>
                                    <Image src={current === 'memo' ? imageTabMemoOn : imageTabMemoOff} width={'32px'} />
                                    <Text fontSize={'10px'} color={current === 'memo' ? 'primary' : undefined}>
                                        メモ
                                    </Text>
                                </Box>

                                <Divider borderColor={'primary'} borderWidth={'1px'} orientation="vertical" />

                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    width={'full'}
                                    cursor={'pointer'}
                                    onClick={() => handlePageTransition('/bookmark')}>
                                    <Image
                                        src={current === 'bookmark' ? imageTabBookmarkOn : imageTabBookmarkOff}
                                        width={'32px'}
                                    />
                                    <Text fontSize={'10px'} color={current === 'bookmark' ? 'primary' : undefined}>
                                        ブックマーク
                                    </Text>
                                </Box>

                                <Divider borderColor={'primary'} borderWidth={'1px'} orientation="vertical" />

                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    width={'full'}
                                    cursor={'pointer'}
                                    onClick={() => handlePageTransition('/setting')}>
                                    <Image src={current === 'setting' ? imageTabSettingOn : imageTabSettingOff} width={'32px'} />
                                    <Text fontSize={'10px'} color={current === 'setting' ? 'primary' : undefined}>
                                        設定・その他
                                    </Text>
                                </Box>
                            </Stack>
                        </Box>
                        <Stack gap={0} color={'#28529b'} margin={'15px 5px 15px auto'}>
                            {me.name && (
                                <>
                                    <Text fontSize={'.7em'}>こんにちは</Text>
                                    <Stack direction={'row'} alignItems={'end'} justifyContent={'center'} spacing={0}>
                                        <Text fontSize={{ base: '1rem', lg: '1rem' }}>{me.name}</Text>
                                        <Text fontSize={'10px'}>さん</Text>
                                    </Stack>
                                </>
                            )}
                        </Stack>
                        <Stack
                            flex={'0 0 84px'}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'end'}
                            spacing={{ base: '0.3rem', lg: '0.5rem' }}>
                            <Box
                                color={pathname.startsWith('/calendar') ? '#fff' : 'deepColor'}
                                borderWidth={'1px'}
                                borderColor={'deepColor'}
                                backgroundColor={pathname.startsWith('/calendar') ? 'deepColor' : '#fff'}
                                borderRadius={'20px'}
                                width={'36px'}
                                height={'36px'}
                                transition={'all .3s ease-in-out'}
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                cursor={'pointer'}
                                onClick={() => handlePageTransition('/calendar')}>
                                <Icon className="MdCalendarToday" as={MdCalendarToday} boxSize={'24px'} />
                            </Box>
                            <Box
                                color={'deepColor'}
                                borderWidth={'1px'}
                                borderColor={'deepColor'}
                                borderRadius={'20px'}
                                width={'36px'}
                                height={'36px'}
                                transition={'all .3s ease-in-out'}
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                cursor={'pointer'}
                                onClick={() => logout()}>
                                <Icon className="MdLogout" as={MdLogout} boxSize={'24px'} />
                            </Box>
                        </Stack>
                    </Box>

                    <Box
                        display={{ base: 'block', lg: 'none' }}
                        pos={'fixed'}
                        bottom={0}
                        flex={'1 1 auto'}
                        w={'full'}
                        backgroundColor={'#fff'}
                        border={'1px solid #4a90e2'}
                        zIndex={999}>
                        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'start'} height={'50px'} py={'0.9rem'}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                width={'full'}
                                cursor={'pointer'}
                                onClick={() => handlePageTransition('/home')}>
                                <Image src={current === 'home' ? imageTabHomeOn : imageTabHomeOff} width={'24px'} />
                                <Text fontSize={'10px'} color={current === 'home' ? 'primary' : undefined}>
                                    ホーム
                                </Text>
                            </Box>

                            <Divider borderColor={'primary'} borderWidth={'1px'} orientation="vertical" />

                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                width={'full'}
                                cursor={'pointer'}
                                onClick={() => handlePageTransition('/memo')}>
                                <Image src={current === 'memo' ? imageTabMemoOn : imageTabMemoOff} width={'24px'} />
                                <Text fontSize={'10px'} color={current === 'memo' ? 'primary' : undefined}>
                                    メモ
                                </Text>
                            </Box>

                            <Divider borderColor={'primary'} borderWidth={'1px'} orientation="vertical" />

                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                width={'full'}
                                cursor={'pointer'}
                                onClick={() => handlePageTransition('/bookmark')}>
                                <Image src={current === 'bookmark' ? imageTabBookmarkOn : imageTabBookmarkOff} width={'24px'} />
                                <Text fontSize={'10px'} color={current === 'bookmark' ? 'primary' : undefined}>
                                    ブックマーク
                                </Text>
                            </Box>

                            <Divider borderColor={'primary'} borderWidth={'1px'} orientation="vertical" />

                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                width={'full'}
                                cursor={'pointer'}
                                onClick={() => handlePageTransition('/setting')}>
                                <Image src={current === 'setting' ? imageTabSettingOn : imageTabSettingOff} width={'24px'} />
                                <Text fontSize={'10px'} color={current === 'setting' ? 'primary' : undefined}>
                                    設定・その他
                                </Text>
                            </Box>
                        </Stack>
                    </Box>
                </>
            )}
        </>
    )
}

// component
export const GHeader: FC<GHeaderProps> = (props: GHeaderProps) => {
    const hookItems = useGHeader()
    return <GHeaderView {...props} {...hookItems} />
}
