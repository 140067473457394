import { Box, Button } from '@chakra-ui/react'
import { type FC, type ReactElement, ReactNode, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { NsPropTypeBase } from '~/types/ns-prop-type-base'

export type CButtonType = 'primary' | 'secondary' | 'danger'

export type CButtonProps = {
    buttonType?: CButtonType
    disabled?: boolean
    backgroundColor?: string
    leftIcon?: ReactElement
    rightIcon?: ReactElement
    onClick?: () => void
    // window.locationによる遷移(多分)
    href?: string
    // react-routerによる遷移
    to?: string
    isLoading?: boolean
    children?: ReactNode
} & NsPropTypeBase

export const useCButton = (props: CButtonProps) => {
    const navigate = useNavigate()

    const buttonType = useMemo(() => {
        return props.buttonType
    }, [props.buttonType])

    const onClick = async () => {
        if (props.onClick) props.onClick()
        if (props.to) navigate(props.to)
    }

    const background = useMemo(() => {
        switch (buttonType) {
            case 'primary':
                return 'primary'
            case 'danger':
                return 'danger'
            default:
                return 'white'
        }
    }, [buttonType])

    const textColor = useMemo(() => {
        switch (buttonType) {
            case 'primary':
                return 'white'
            case 'danger':
                return 'white'
            default:
                return 'primary'
        }
    }, [buttonType])

    const variant = useMemo(() => {
        switch (buttonType) {
            case 'primary':
                return 'solid'
            case 'danger':
                return 'solid'
        }
        return 'outline'
    }, [buttonType])

    const borderColor = useMemo(() => {
        switch (buttonType) {
            case 'primary':
                return 'primary'
            case 'danger':
                return 'danger'
        }
        return 'primary'
    }, [buttonType])

    const opacity = useMemo(() => {
        return props.disabled ? '0.4' : '1'
    }, [props.disabled])

    return {
        buttonType,
        onClick,
        background,
        textColor,
        borderColor,
        variant,
        opacity,
    }
}

export const CButtonView: FC<CButtonProps & ReturnType<typeof useCButton>> = (props) => {
    const {
        leftIcon,
        rightIcon,
        children,
        disabled,
        _style,
        onClick,
        background,
        textColor,
        borderColor,
        variant,
        opacity,
        isLoading,
    } = props

    return (
        <>
            <Button
                disabled={disabled}
                isLoading={isLoading}
                data-testid="c-button"
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                background={background}
                color={textColor}
                _hover={{ bg: background }}
                h={'2.0rem'}
                onClick={() => onClick()}
                borderRadius={'4px'}
                borderWidth={variant === 'outline' ? 1 : 0}
                borderColor={borderColor}
                opacity={opacity}
                p={0}
                fontSize={'16px'}
                fontWeight={400}
                variant={variant}
                {..._style}>
                <Box px={4}>{children}</Box>
            </Button>
        </>
    )
}

export const CButton: FC<CButtonProps> = (props: CButtonProps) => {
    const hookItems = useCButton(props)
    return <CButtonView {...props} {...hookItems} />
}
