import { atom, useAtom } from 'jotai'
import { ReactNode } from 'react'

export type DialogActionType = {
    label: string
    type?: 'danger' | 'primary'
    onClick?: () => void
}

export type DialogContentType = {
    title?: string
    body: ReactNode | string
    actions: DialogActionType[]
}

const dialogContentAtom = atom<DialogContentType | null>(null)

export const useDialog = () => {
    const [dialogContent, setDialogContent] = useAtom(dialogContentAtom)

    const showDialog = (content: DialogContentType) => {
        setDialogContent(content)
    }

    const closeDialog = () => {
        setDialogContent(null)
    }

    return {
        showDialog,
        closeDialog,
        dialogContent,
    }
}
