import dayjs from 'dayjs'
import { type FC, type ReactNode, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useAccountSettingState } from '~/hooks/store/use-account-setting-state'
import { useAuthState } from '~/hooks/store/use-auth-state'
import { useUserParamState } from '~/hooks/store/use-user-param-state'
import { useApiClient } from '~/util/create-api-client'

type Props = {
    children?: ReactNode
}

export const LoginShell: FC<Props> = ({ children }) => {
    const { apiClient, hookApi } = useApiClient()
    const location = useLocation()
    const navigate = useNavigate()
    const { accessToken, me, clearAll, setAccessToken } = useAuthState()
    const { resetUserParamState } = useUserParamState()
    const { setAccountSetting } = useAccountSettingState()

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;(async () => {
            const ignorePaths = ['/', '/user/signIn', '/user/signIn/external', '/resetPass']
            if (ignorePaths.some((path) => location.pathname.match(path))) {
                if (location.pathname.match('/resetPass')) return
                if (location.pathname.match('/user/signIn/external')) return
                if (location.pathname.match('/user/signIn')) {
                    clearAll()
                    resetUserParamState()
                    navigate('/user/signIn')
                }
            } else if (!accessToken || !me) navigate('/user/signIn')
            else {
                const diff = dayjs(accessToken.token_created_at).add(accessToken.expires_in, 'seconds').diff(dayjs(), 'minutes')
                if (diff <= 5) {
                    try {
                        const res = await hookApi(() =>
                            apiClient.clientAuthRefreshToken({ requestBody: { refreshToken: accessToken.refresh_token } }),
                        )
                        setAccessToken({
                            ...res,
                            refresh_token: accessToken.refresh_token,
                            token_created_at: new Date(),
                        })
                    } catch (e) {
                        navigate('/user/signIn')
                    }
                }
                try {
                    const setting = await hookApi(() => apiClient.clientAccountSettingIndex({}))
                    setAccountSetting(setting)
                } catch (e) {
                    navigate('/user/signIn')
                }
            }
        })()
        // パスの変更のみを監視したい
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return <>{children}</>
}
