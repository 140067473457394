import {
    Link as ChakraLink,
    Button as ChakraButton,
    LinkProps as ChakraLinkProps,
    ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react'
import { FC, useMemo } from 'react'
import {
    Link as ReactRouterLink,
    LinkProps as ReactRouterLinkProps,
    NavLink as ReactRouterNavLink,
    NavLinkProps as ReactRouterNavLinkProps,
} from 'react-router-dom'

import { type DetectEmptyObject } from '~/util/common'
import { buildRouteParams } from '~/util/router/build-route-params'
import { type RouteParams, type RouteType } from '~/util/router/types'

type RouteTypedToProps<T extends RouteType> = {
    to: DetectEmptyObject<RouteParams<T>> extends never ? [T] : [T, RouteParams<T>]
}

export const Link: FC<ReactRouterLinkProps & ChakraLinkProps> = (props) => <ChakraLink as={ReactRouterNavLink} {...props} />
export const LinkButton: FC<ReactRouterLinkProps & ChakraButtonProps> = (props) => (
    <ChakraButton as={ReactRouterLink} {...props} />
)

export type TypedLinkProps<T extends RouteType> = Omit<ReactRouterLinkProps & ChakraLinkProps, 'to'> & RouteTypedToProps<T>
export const TypedLink = <T extends RouteType>({ to, ...props }: TypedLinkProps<T>) => {
    const url = useMemo(() => buildRouteParams(to[0], to[1]), [to])
    return <Link {...props} to={url} />
}

export const NavLink: FC<ReactRouterNavLinkProps & ChakraLinkProps> = (props) => <ChakraLink as={ReactRouterLink} {...props} />

export type TypedNavLinkProps<T extends RouteType> = Omit<ReactRouterNavLinkProps & ChakraLinkProps, 'to'> & RouteTypedToProps<T>
export const TypedNavLink = <T extends RouteType>({ to, ...props }: TypedNavLinkProps<T>) => {
    const url = useMemo(() => buildRouteParams(to[0], to[1]), [to])
    return <NavLink {...props} to={url} />
}
