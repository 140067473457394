import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    Flex,
    HStack,
    Icon,
    Link,
    Show,
    Stack,
    useDisclosure,
    Image,
    VStack,
    Divider,
} from '@chakra-ui/react'
import { FC, ReactNode, useEffect, useState } from 'react'
import { CiLogin } from 'react-icons/ci'
import { RxHamburgerMenu } from 'react-icons/rx'
import LpLogo from '~/assets/images/lp/logo.png'
import { LinkButton } from '~/renderer/Link'
import { NsPropTypeBase } from '~/types/ns-prop-type-base'

export type CLpHeaderProps = {
    children?: ReactNode
} & NsPropTypeBase

// Custom hook for CLpHeader logic
const useCLpHeader = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const menuItems = [
        { title: '特長', href: '#features' },
        { title: '料金プラン', href: '#paymentPlan' },
        { title: '選ばれる理由', href: '#reasonToChoose' },
        { title: 'よくある質問', href: '#faq' },
    ]

    const INQUIRY =
        'https://lcbform.nikkei.com/IQ-lissn.html?gad_source=1&gclid=CjwKCAjwupGyBhBBEiwA0UcqaLzhtHnGcgoowhXByqgt3xZ53_3ns_t0Pi8g1iha7HHhG-JBYugU5BoCZaMQAvD_BwE&_gl=1*fkhbpo*_gcl_aw*R0NMLjE3MTYxOTEyMjMuQ2p3S0NBand1cEd5QmhCQkVpd0EwVWNxYUx6aHRIbkdjZ29vd2hYQnlxZ3QzeFo1M18zbnNfdDBQaThnMWloYTdISGhHLUpCWXVnVTVCb0NaYU1RQXZEX0J3RQ..*_gcl_au*MTc1NDA1ODIwNi4xNzE1NzY3ODM2*_ga*NzI3MzU3MzE2LjE3MTU3Njc4Mzc.*_ga_YBJBZG7G9T*MTcxNjE5MTIyMy40LjAuMTcxNjE5MTIyMy42MC4wLjA.'

    const buttonItems = [
        {
            title: '法人のお客様',
            href: INQUIRY,
        },
        {
            title: 'お申し込み',
            href: '#paymentPlan',
        },
    ]
    const [visible, setVisible] = useState(true)
    const [scrollTimeout, setScrollTimeout] = useState(0)

    const handleScroll = () => {
        setVisible(false)
        if (scrollTimeout !== 0) clearTimeout(scrollTimeout)
        setScrollTimeout(
            window.setTimeout(() => {
                setVisible(true)
            }, 400),
        )
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            if (scrollTimeout) clearTimeout(scrollTimeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollTimeout])

    return { menuItems, INQUIRY, isOpen, onOpen, onClose, buttonItems, visible }
}

// View component for CLpHeader
const CLpHeaderView: FC<CLpHeaderProps & ReturnType<typeof useCLpHeader>> = ({
    menuItems,
    isOpen,
    onOpen,
    onClose,
    buttonItems,
    visible,
}) => (
    <>
        <Flex
            data-testid="c-lp-header"
            width="full"
            height={{ base: '44px', lg: '68px' }}
            justifyContent="space-between"
            alignItems="center"
            px={{ base: '1.25rem', xl: '2.5rem' }}
            py={{ base: '0.375rem', xl: '0.75rem' }}
            backgroundColor="white"
            transition="transform 0.6s ease"
            transform={visible ? 'translateY(0)' : 'translateY(-100%)'}
            position="fixed"
            top="0"
            zIndex="9999">
            <Image width={{ base: '7.25rem', lg: '9.125rem' }} src={LpLogo} alt="Lissn Logo" />
            <HStack spacing={{ base: '1rem', xl: '1.5rem' }}>
                <Show below="lg">
                    <Icon
                        as={RxHamburgerMenu}
                        color="lpPrimary"
                        onClick={isOpen ? onClose : onOpen}
                        h="auto"
                        boxSize={6}
                        mx={2}
                        position={'relative'}
                    />
                </Show>
                <Show above="lg">
                    {menuItems.map((item, index) => (
                        <Button as={Link} key={index} variant="link" href={item.href} color={'lpPrimary'}>
                            {item.title}
                        </Button>
                    ))}
                    {buttonItems.map((it, index) => (
                        <Button
                            key={index}
                            borderRadius="0.5rem"
                            backgroundColor="lpPrimary"
                            as={Link}
                            color={'white'}
                            href={it.href}
                            _hover={{ bg: '#87A5DF', textDecor: 'none' }}>
                            {it.title}
                        </Button>
                    ))}
                    <LinkButton
                        to="/user/signin"
                        leftIcon={<Icon as={CiLogin} boxSize={6} />}
                        color="lpPrimary"
                        bg={'white'}
                        px={0}
                        _hover={{ bg: '#87A5DF' }}>
                        ログイン
                    </LinkButton>
                </Show>
            </HStack>
        </Flex>
        <Drawer isOpen={isOpen} onClose={onClose} autoFocus={false} placement="bottom">
            <DrawerContent bg="lpPrimary" h={'full'} position={'relative'} justifyContent="start">
                <DrawerBody p={0}>
                    <Stack spacing="2.5rem" color="white" px="2.5rem" pb="3.5rem" pt={'6.25rem'}>
                        {menuItems.map((item, index) => (
                            <Link
                                key={index}
                                justifyContent={'start'}
                                h={'min-content'}
                                href={item.href}
                                onClick={onClose}
                                fontWeight="semibold">
                                {item.title}
                            </Link>
                        ))}
                        <VStack spacing={'1rem'}>
                            {buttonItems.map((it, index) => (
                                <Button
                                    key={index}
                                    borderRadius="5px"
                                    color="lpPrimary"
                                    w="full"
                                    py={'1.25rem'}
                                    h={'auto'}
                                    backgroundColor="white"
                                    as={Link}
                                    href={it.href}>
                                    {it.title}
                                </Button>
                            ))}
                        </VStack>
                    </Stack>
                    <Divider height="0.125rem" opacity="1" borderRadius="1.5rem" fill="white" />
                </DrawerBody>
                <DrawerFooter justifyContent="start" py="3.5rem">
                    <LinkButton to="/user/signin" leftIcon={<Icon as={CiLogin} />} bgColor="lpPrimary" color="white">
                        ログイン
                    </LinkButton>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    </>
)

// Main component for CLpHeader
const CLpHeader: FC<CLpHeaderProps> = (props: CLpHeaderProps) => {
    const hookItems = useCLpHeader()
    return <CLpHeaderView {...props} {...hookItems} />
}

export { CLpHeader, useCLpHeader, CLpHeaderView }
