import React from "react";
const __pages_import_0__ = React.lazy(() => import("/src/routes/calendar.tsx"));
const __pages_import_1__ = React.lazy(() => import("/src/routes/home.tsx"));
const __pages_import_2__ = React.lazy(() => import("/src/routes/index.tsx"));
const __pages_import_3__ = React.lazy(() => import("/src/routes/info.tsx"));
const __pages_import_4__ = React.lazy(() => import("/src/routes/setting.tsx"));
const __pages_import_5__ = React.lazy(() => import("/src/routes/variousSettings.tsx"));
const __pages_import_6__ = React.lazy(() => import("/src/routes/bookmark/[articleUuid].tsx"));
const __pages_import_7__ = React.lazy(() => import("/src/routes/bookmark/index.tsx"));
const __pages_import_8__ = React.lazy(() => import("/src/routes/detail/[articleUuid].tsx"));
const __pages_import_9__ = React.lazy(() => import("/src/routes/memo/[noteUuid].tsx"));
const __pages_import_10__ = React.lazy(() => import("/src/routes/memo/index.tsx"));
const __pages_import_11__ = React.lazy(() => import("/src/routes/resetPass/[hash].tsx"));
const __pages_import_12__ = React.lazy(() => import("/src/routes/resetPass/index.tsx"));
const __pages_import_13__ = React.lazy(() => import("/src/routes/user/signin/external.tsx"));
const __pages_import_14__ = React.lazy(() => import("/src/routes/user/signin/index.tsx"));

const routes = [{"caseSensitive":false,"path":"calendar","element":React.createElement(__pages_import_0__)},{"caseSensitive":false,"path":"home","element":React.createElement(__pages_import_1__)},{"caseSensitive":false,"path":"/","element":React.createElement(__pages_import_2__)},{"caseSensitive":false,"path":"info","element":React.createElement(__pages_import_3__)},{"caseSensitive":false,"path":"setting","element":React.createElement(__pages_import_4__)},{"caseSensitive":false,"path":"variousSettings","element":React.createElement(__pages_import_5__)},{"caseSensitive":false,"path":"bookmark","children":[{"caseSensitive":false,"path":":articleUuid","element":React.createElement(__pages_import_6__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_7__)}]},{"caseSensitive":false,"path":"detail","children":[{"caseSensitive":false,"path":":articleUuid","element":React.createElement(__pages_import_8__)}]},{"caseSensitive":false,"path":"memo","children":[{"caseSensitive":false,"path":":noteUuid","element":React.createElement(__pages_import_9__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_10__)}]},{"caseSensitive":false,"path":"resetPass","children":[{"caseSensitive":false,"path":":hash","element":React.createElement(__pages_import_11__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_12__)}]},{"caseSensitive":false,"path":"user","children":[{"caseSensitive":false,"path":"signin","children":[{"caseSensitive":false,"path":"external","element":React.createElement(__pages_import_13__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_14__)}]}]}];

export default routes;